// 年月日，时分秒
//输出限时 2020-08-15 16:35
export function getFullTimeNow() {
  let date = new Date(), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    /* eslint-disable */
    Y = date.getFullYear() + "",
    M =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1,
    D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
    h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
    m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

  return Y + "-" + M + "-" + D + " " + h + ":" + m;
  /* eslint-enable */
}

// 年月日，时分秒
//输出个时 2020-08-15 16:35
export function getFullTime(timeStamp) {
  let date = new Date(timeStamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

  /* eslint-disable */
  const Y = date.getFullYear();
  const M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const m =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  // const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  // return Y + M + D + h + m + s

  return Y + "年" + M + "月" + D + "日" + h + ":" + m;
  /* eslint-enable */
}

export function isPoneAvailable(poneInput) {
  let myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
  if (!myreg.test(poneInput)) {
    return false;
  } else {
    return true;
  }
}

// 返回时间戳 time格式 2020/01/01
function returnTimeStamp(time) {
  let arr = time.split("/");
  let starttime = new Date(arr[0], arr[1], arr[2]);
  let timeStamp = starttime.getTime();

  return timeStamp;
}

// 跟据出生年月、年龄，返回个人项目组别（中文）
export function returnGroupZhByBirth(birth, sex) {
  console.log("sex", sex);

  let birthTimeStamp = returnTimeStamp(birth.replace(/-/g, "/"));
  switch (true) {
    case returnTimeStamp("1955 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("1964 / 12 / 31") >= birthTimeStamp:
      return `常青组`;
    case returnTimeStamp("1965 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("1984 / 12 / 31") >= birthTimeStamp:
      return `中年组`;
    case returnTimeStamp("1985 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2006 / 12 / 31") >= birthTimeStamp:
      return `成年组`;
    case returnTimeStamp("2007 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2011 / 12 / 31") >= birthTimeStamp:
      return `青少年组`;
    case returnTimeStamp("2012 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2017 / 12 / 31") >= birthTimeStamp:
      return `儿童组`;
    case returnTimeStamp("2018 / 1 / 1") <= birthTimeStamp:
      return `幼儿组`;
    default:
      return false;
  }
}

// 跟据出生年月、年龄，返回集体项目的组别（中文）
export function returnTeamGroupZhByBirth(birth, sex) {
  let birthTimeStamp = returnTimeStamp(birth.replace(/-/g, "/"));
  switch (true) {
    case returnTimeStamp("1955 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("1974 / 12 / 31") >= birthTimeStamp:
      return `常青组`;
    case returnTimeStamp("1975 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2006 / 12 / 31") >= birthTimeStamp:
      return `成年组`;
    case returnTimeStamp("2007 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2011 / 12 / 31") >= birthTimeStamp:
      return `青少年组`;
    case returnTimeStamp("2012 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2017 / 12 / 31") >= birthTimeStamp:
      return `儿童组`;
    case returnTimeStamp("2018 / 1 / 1") <= birthTimeStamp:
      return `幼儿组`;
    default:
      return false;
  }
}


// 跟据出生年月、年龄，返回推手项目的组别（中文）
export function returnTuishouGroupZhByBirth(birth, sex) {
  let birthTimeStamp = returnTimeStamp(birth.replace(/-/g, "/"));
  switch (true) {
    case returnTimeStamp("1964 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("1978 / 12 / 31") >= birthTimeStamp:
      return `常青组`;
    case returnTimeStamp("1979 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2005 / 12 / 31") >= birthTimeStamp:
      return `成年组`;
    case returnTimeStamp("2006 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2008 / 12 / 31") >= birthTimeStamp:
      return `高中组`;
    case returnTimeStamp("2009 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2012 / 12 / 31") >= birthTimeStamp:
      return `初中组`;
    default:
      return false;
  }
}




// 返回武术竞赛组别
export function returnGroupZhById(groupZh) {
  switch (groupZh) {
    case 1:
      return "高中组";
    case 2:
      return "初中组";
    case 3:
      return "小学甲组";
    case 4:
      return "小学乙组";
    case 5:
      return "幼儿园组";
    default:
      return false;
  }
}

// 返回竞赛组别,集体（参赛规则规定17周岁以下是少年儿童组，18周岁以上是成年组）
export function returnGroupJt(birth) {
  let birthTimeStamp = returnTimeStamp(birth.replace(/-/g, "/"));
  switch (true) {
    case returnTimeStamp("2004 / 12 / 31") >= birthTimeStamp:
      return 1;
    case returnTimeStamp("2007 / 12 / 31") >= birthTimeStamp &&
      birthTimeStamp >= returnTimeStamp("2005 / 1 / 1"):
      return 2;
    case returnTimeStamp("2009 / 12 / 31") >= birthTimeStamp &&
      birthTimeStamp >= returnTimeStamp("2008 / 1 / 1"):
      return 3;
    case returnTimeStamp("2014 / 12 / 31") >= birthTimeStamp &&
      birthTimeStamp >= returnTimeStamp("2010 / 1 / 1"):
      return 4;
    case birthTimeStamp >= returnTimeStamp("2015 / 1 / 1"):
      return 5;
    default:
      return false;
  }
}

export function returnSandaGroupZhByBirth(birth) {
  let birthTimeStamp = returnTimeStamp(birth.replace(/-/g, "/"));
  switch (true) {
    case returnTimeStamp("2007 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2008 / 12 / 31") >= birthTimeStamp:
      return "少年甲组";
    case returnTimeStamp("2009 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2010 / 12 / 31") >= birthTimeStamp:
      return "少年乙组";
    case returnTimeStamp("2011 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2012 / 12 / 31") >= birthTimeStamp:
      return "少年乙组";

    case returnTimeStamp("2013 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2014 / 12 / 31") >= birthTimeStamp:
      return "少儿甲组";
    case returnTimeStamp("2015 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2016 / 12 / 31") >= birthTimeStamp:
      return "少儿乙组";
    case returnTimeStamp("2017 / 1 / 1") <= birthTimeStamp &&
      returnTimeStamp("2018 / 12 / 31") >= birthTimeStamp:
      return "少儿丙组";

    default:
      return false;
  }
}
